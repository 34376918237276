import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { light, dark } from './palette';

const hr = (new Date()).getHours(); //get hours of the day in 24Hr format (0-23)
const modeDetect = hr >= 7 && hr <= 21 ? 'light' : 'dark';

const getTheme = mode => responsiveFontSizes(
  createMuiTheme({
    //palette: mode === modeDetect ? light : dark,
    palette: mode = dark,
    layout: {
      contentWidth: 1236,
    },
    typography: {
      fontFamily: 'Lato',
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  }),
);

export default getTheme;
