export const light = {
  "alternate": {
    "main": "rgb(247, 249, 250)",
    "dark": "#DFE3EC",
  },
  "cardShadow": "rgba(23, 70, 161, .11)",
  "type": "light",
  "primary": {
    "light": "rgba(123, 189, 66, 0.87)",
    "main": "#7bbd42",
    "dark": "#4C9A09",
    "contrastText": "#fff"
  },
  "secondary": {
    "light": "#ffd54f",
    "main": "#ffb300",
    "dark": "#ff6f00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "text": {
    "primary": "#21281D",
    "secondary": "#718096",
  },
  "divider": "rgba(0, 0, 0, 0.12)",
  "background": {
    "paper": "#fff",
    "default": "#fff",
    "level2": "#f5f5f5",
    "level1": "#fff",
    "footer": "#3C4754",
  },
};

export const dark = {
  "alternate": {
    "main": "#2D3748",
    "dark": "#24242b",
  },
  "cardShadow": "rgba(0, 0, 0, .11)",
  "common": {
    "black": "#000",
    "white": "#fff"
  },
  "type": "dark",
  "primary": {
    "light": "#93DF51",
    "main": "#7bbd42",
    "dark": "#4C9A09",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "secondary": {
    "light": "#ffb74d",
    "main": "#f9b934",
    "dark": "#f57c00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "text": {
    "primary": "#EEEEEF",
    "secondary": "#AEB0B4",
  },
  "divider": "rgba(255, 255, 255, 0.12)",
  "background": {
    "paper": "#1A202C",
    "default": "#121212",
    "level2": "#333",
    "level1": "#2D3748",
    "footer": "#151a23",
  },
};